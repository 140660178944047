















































































































































































































































import { Component } from "vue-property-decorator";
import Vue from "vue";
import { AxiosResponse } from "axios";
import { UserApi } from "@/networks/UserApi";
import { CouponApi } from "@/networks/CouponApi";
import store from "@/store";

@Component({})
export default class Pay extends Vue {
  private event: Record<string, any> = {};
  private error: Record<string, any> = {};
  private loading = false;
  private coupon = "";
  private inviteFriend = "";
  private price = 0;
  private bank = "ZarinGate";
  private bankList = [
    {
      text: "مناسب ترین درگاه",
      value: "ZarinGate"
    },
    {
      text: "بانک ملت",
      value: "Bpm"
    },
    {
      text: "پرداخت نوین آرین",
      value: "Pna"
    },
    {
      text: "بانک پاسارگاد",
      value: "Pep"
    },
  ];
  use_coupon = false;
  mounted() {
    this.getEvent();
    document.title = (this.$route.meta as any).title || "GO2TR BMS";
  }

  public async getEvent(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().getEvent(
        this.$route.params.event,
        this.$route.params.check
      );
      this.event = res.data.data;
      this.price = res.data.data.event.price;
    } catch (error: any) {
      this.error = error.response.data;
    }
  }
  async checkCoupon() {
    try {
      const res: AxiosResponse = await new CouponApi().check(this.coupon);
      if (res) {
        this.use_coupon = true;
        if (res.data.data.type == "percent") {
          this.price = (this.event.event.price * res.data.data.amount) / 100;
        } else {
          this.price = this.event.event.price - res.data.data.amount;
        }
        store.commit("showError", {
          message: res.data.message || "کد تخفیف اعمال شد",
          color: "success"
        });
      }
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "error"
      });
    }
  }
  public async pay(): Promise<void> {
    this.loading = true;
    try {
      const res: AxiosResponse = await new UserApi().getEvent(
        this.$route.params.event,
        this.$route.params.check,
        true,
        this.use_coupon ? this.coupon : "",
        this.inviteFriend
      );
      let link = res.data.data.url.action;
      link = link.replace('ZarinGate', '');

      window.location.href = `${link}${this.bank}`;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    } finally {
      this.loading = false;
    }
  }
}
